import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionMission.module.css';

const SectionMission = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.content}>
        <h2 className={css.title}>
          <FormattedMessage id="SectionMission.title" />
        </h2>

        <div className={css.card}>
          <p className={css.cardText}>
            <FormattedMessage id="SectionMission.cardContent" />
          </p>
        </div>
      </div>
    </div>
  );
};

SectionMission.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionMission.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionMission;
